export const environment = {
  production: window['env']['production'] || false,
  apiUrl: window['env']['apiUrl'] || 'https://portal.api.alpha.cloudfactory.dk/',
  //apiUrl: window["env"]["apiUrl"] || "https://localhost:5000",
  dropboxApiUrl: window['env']['dropboxApiUrl'] || 'http://10.245.110.243:8002',
  logoUrl: window['env']['logoUrl'] || 'https://portal.api.alpha.cloudfactory.dk/Partners/{id}/Logo',
  acronisApiUrl: window['env']['acronisApiUrl'] || 'https://portal.api.alpha.cloudfactory.dk/acronis',
  customerapiUrl: window['env']['customerapiUrl'] || 'https://portal.api.alpha.cloudfactory.dk/v2/customers/',
  partnerapiUrl: window['env']['partnerapiUrl'] || 'https://portal.api.alpha.cloudfactory.dk/v2/partners/',
  ESETApiUrl: window['env']['esetapiUrl'] || 'https://portal.api.alpha.cloudfactory.dk/eset',
  helloSignUrl: window['env']['helloSignapiUrl'] || 'https://portal.api.alpha.cloudfactory.dk/hellosign',
  dropboxApiUrlV2: window['env']['dropboxApiUrlV2'] || 'https://portal.api.alpha.cloudfactory.dk/dropbox/v2',
  catalogueApiUrl: window['env']['catalogueApiUrl'] || 'https://portal.api.alpha.cloudfactory.dk/v2/catalogue/',
  notificationApiUrl: window['env']['notificationApiUrl'] || 'https://portal.api.alpha.cloudfactory.dk/notification/',
  exclaimerApiUrl: window['env']['exclaimerApiUrl'] || 'https://portal.api.alpha.cloudfactory.dk/exclaimer',
  keepItApiUrl: window['env']['keepItApiUrl'] || 'https://portal.api.alpha.cloudfactory.dk/keepit',
  billingApiUrl: window['env']['billingApiUrl'] || 'https://portal.api.alpha.cloudfactory.dk/billing',
  microsoftV2API: window['env']['microsoftV2API'] || 'https://portal.api.alpha.cloudfactory.dk/v2/microsoft',
  gdapAPIUrl: window['env']['gdap'] || 'https://portal.api.alpha.cloudfactory.dk/microsoft-gdap',
  activityLogApiUrl: window['env']['activityLogApiUrl'] || 'https://portal.api.alpha.cloudfactory.dk/activity-logs',
  intercomProxyUrl: window['env']['intercomProxyUrl'] || 'https://portal.api.alpha.cloudfactory.dk/v1/intercomproxy',
  microsoftTransfersApiUrl: window['env']['microsoftTransfersApiUrl'] || 'https://portal.api.alpha.cloudfactory.dk/v1/microsofttransfers',
  impossibleCloudUrl: window['env']['impossibleCloudUrl'] || 'https://portal.api.alpha.cloudfactory.dk/v1/impossiblecloud',

  //ESETApiUrl: window["env"]["ESETApiUrl"] || "http://localhost:5500",
  //apiUrl: window["env"]["apiUrl"] || "https://localhost:5000",

  auth: {
    authorizationParams: {
      redirect_uri: window['env']['auth_redirecturi'] || 'https://partnerportal.cf/callback',
      audience: window['env']['auth_audience'] || 'https://dev.api.cfdev.local/',
    },
    domain: window['env']['auth_domain'] || 'utilityportal-dev.eu.auth0.com',
    clientId: window['env']['auth_clientid'] || 'pJf55rKzFNarVUccp7BEyFwt1YJ5v0Sa',
  },
  EndCustomerMicrosoftRoleId: window['env']['EndCustomerMicrosoftRoleId'] || 23,
  vmConsoleUrl: window['env']['vmConsoleUrl'] || 'https://terminal.alpha.cloudfactory.dk',

  featureFlags: {
    microsoft_AlignEndDate: true,
  },
  openReplay: {
    enabled: window['env']['openReplay_enabled'] || false,
    projectKey: window['env']['openReplay_projectKey'] || 'qa3xVz2LBikwkUMsPZyg',
    ingestPoint: window['env']['openReplay_ingestPoint'] || 'https://openreplay.cloudfactory.dk/ingest',
  },
  clarityProjectId: window['env']['clarityProjectId'] || 'ottb5lvxix',
};
